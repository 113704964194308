import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Button.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const Submit = ({ category, outline, children, ...props }) => {
  const buttonVariant = outline
    ? styles[`button--outline-${category}`]
    : styles[`button--${category}`];

  return (
    <button
      className={classNames(styles.button, buttonVariant)}
      type="submit"
      {...props}
    >
      {children}
    </button>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

Submit.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  outline: PropTypes.bool,
};

Submit.defaultProps = {
  outline: false,
};

export default Submit;
