import React from 'react';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Container from 'src/components/global/layouts/Container';
import SectionHeader from 'src/components/global/section-headers/Dynamic';
import LinkContainer from 'src/components/columnist/feeds/LinkContainer';
import DividerWithContent from 'src/components/global/dividers/DividerWithContent';
import Button from 'src/components/global/buttons/Button';

// Advertising
import FullAdvertisingWrapper from 'src/components/ads/wrappers/FullAdvertisingWrapper';

import styles from './ArchivesFeed.module.scss';

const ArchivesFeed = ({
  featureName,
  featureShortName,
  category,
  articles,
  loadMoreArticles,
  stopFeed,
  isLoading,
}) => {
  const router = useRouter();

  return (
    <>
      <Container>
        <div className={styles.archive__header}>
          <SectionHeader priority={1}>Archives</SectionHeader>
        </div>
        <LinkContainer
          article={articles[0]}
          category={category}
          clickPosition={1}
          featureName={featureName}
          featureShortName={featureShortName}
          // Add 'latest' label if displaying archives for current year
          latest={!router.query.year}
        />
      </Container>

      <FullAdvertisingWrapper inFeed padded />

      <Container>
        {articles.slice(1).map((article, index, array) => (
          <LinkContainer
            article={article}
            category={category}
            clickPosition={index + 2}
            featureName={featureName}
            featureShortName={featureShortName}
            key={uuidv4()}
            withBorder={index !== array.length - 1}
          />
        ))}
        {!stopFeed && (
          <DividerWithContent padded>
            <Button
              ariaLabel="Load More Articles"
              category={category}
              disabled={isLoading}
              onClick={() => loadMoreArticles()}
            >
              {isLoading ? 'Loading' : 'Load More'}
            </Button>
          </DividerWithContent>
        )}
      </Container>
    </>
  );
};

ArchivesFeed.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishDate: PropTypes.string.isRequired,
      teaser: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  category: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadMoreArticles: PropTypes.func.isRequired,
  stopFeed: PropTypes.bool.isRequired,
};

export default ArchivesFeed;
