import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/node';
import PropTypes from 'prop-types';

import { onPageNavigationEvent } from 'src/lib/analytics';
import getPreviousDay from 'src/lib/utilities/getPreviousDay';
import isEndOfFeed from 'src/lib/utilities/feature/isEndOfFeed';

import ArchivesFeed from 'src/components/columnist/feeds/ArchivesFeed';
import TopicsFeed from 'src/components/columnist/feeds/TopicsFeed';

const FeedContainer = ({
  featureName,
  featureShortName,
  category,
  articles,
  feedType,
  endDate,
  topic,
}) => {
  const router = useRouter();

  const [moreArticles, addMoreArticles] = useState([]);
  const [lastArticleDate, setLastArticleDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [stopFeed, setStopFeed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadMoreArticles = () => {
    setIsLoading(true);

    const page = currentPage + 1;

    onPageNavigationEvent('feed', 'button', 'load more');

    // Track which date to request next by getting the last article and subtracting one day
    // If there isn't an article for that day, the service searches back in time for the next article
    const requestStartDate = getPreviousDay(lastArticleDate);
    const requestCount = feedType === 'topic' ? 5 : 30;

    /* eslint-disable max-len */
    const requestUrl =
      feedType === 'topic'
        ? `/api/service/v1/articles/feature-topics/${featureShortName}?topic=${topic}&count=${requestCount}&startDate=${requestStartDate}`
        : `/api/service/v1/articles/feature-archives/${featureShortName}?startDate=${requestStartDate}&endDate=${endDate}&count=${requestCount}&strictStartDate=false`;
    /* eslint-enable max-len */

    fetch(requestUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(`${response.status} ${response.statusText}`);
      })
      .then((data) => {
        const articlesPayload = feedType === 'topic' ? data.articles : data;

        if (isEndOfFeed(requestCount, articlesPayload.length)) {
          setStopFeed(true);
        }

        articlesPayload.forEach((article) =>
          // eslint-disable-next-line no-shadow
          addMoreArticles((moreArticles) => [...moreArticles, article])
        );
        setCurrentPage(page);
        setIsLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  // Reset the 'load more' state variables
  // when the route changes
  useEffect(() => {
    const handleRouteChange = () => {
      addMoreArticles([]);
      setLastArticleDate('');
      setCurrentPage(1);
      setStopFeed(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  // Track the publish date of the last
  // article in the feed for 'load more'
  useEffect(() => {
    if (moreArticles.length > 0) {
      const lastArticle = moreArticles[moreArticles.length - 1];
      setLastArticleDate(lastArticle.publishDate);
    } else if (articles.length > 0) {
      const lastArticle = articles[articles.length - 1];
      setLastArticleDate(lastArticle.publishDate);
    } else {
      // eslint-disable-next-line no-console
      console.log('FeedContainer: There is no articles that were returned.');
    }
  }, [moreArticles, articles]);

  // Check that the max number of articles was
  // received in the initial payload
  useEffect(() => {
    if (isEndOfFeed(15, articles.length)) {
      setStopFeed(true);
    }
  }, [articles]);

  const feedProps = {
    featureName,
    featureShortName,
    category,
    articles: [...articles, ...moreArticles],
    loadMoreArticles,
    stopFeed,
    isLoading,
  };

  return feedType === 'archive' ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ArchivesFeed {...feedProps} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TopicsFeed {...feedProps} currentPage={currentPage} />
  );
};

FeedContainer.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      publishDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  category: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  feedType: PropTypes.oneOf(['archive', 'latest', 'topic']).isRequired,
  topic: PropTypes.string,
};

FeedContainer.defaultProps = {
  endDate: null,
  topic: null,
};

export default FeedContainer;
