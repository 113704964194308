import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import is from 'is_js';
import * as Sentry from '@sentry/node';
import PropTypes from 'prop-types';

import verifyReCaptcha from 'src/lib/verifyReCaptcha';
import {
  subscribeAttemptEvent,
  subscribeSuccessEvent,
} from 'src/lib/analytics';

import SectionHeader from 'src/components/global/section-headers/Static';
import Submit from 'src/components/global/buttons/Submit';

import styles from './NewsletterForm.module.scss';

const NewsletterForm = ({ formId, split, displayContext }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [formSubmitMessage, setFormSubmitMessage] = useState(null);

  const { register, errors, handleSubmit, reset, formState } = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
  });
  const { dirtyFields, isSubmitSuccessful } = formState;

  const labelClass = (isDirty, isError) => {
    const valid = isDirty && !isError;
    return `${styles.form__group} ${valid ? styles.form__valid : ''} ${
      isError ? styles.form__invalid : ''
    }`;
  };

  const iconClass = (isDirty, isError) => {
    const valid = isDirty && !isError;
    return `${styles.form__icon} ${valid ? 'oi oi-check' : ''} ${
      isError ? 'oi oi-x' : ''
    }`;
  };

  const onSubmit = async (formData) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('da_newsletter_signup');
    const human = await verifyReCaptcha(token);

    if (human) {
      subscribeAttemptEvent('dear abby'); // GTM

      // Server side POST to reg service
      fetch(`/api/dear-abby-subscribe?email=${formData.email}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response;
        })
        .then((data) => {
          if (data.response.status === 'new') {
            subscribeSuccessEvent('dear abby'); // GTM
            setFormSubmitMessage({
              type: 'success',
              text: 'You have successfully subscribed to the Dear Abby email.',
            });
          } else if (data.response.status === 'existing') {
            setFormSubmitMessage({
              type: 'error',
              text: 'You are already subscribed to the Dear Abby email.',
            });
          } else {
            setFormSubmitMessage({ type: 'error', text: data.statusText });
            throw new Error(data.statusText);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    } else {
      setFormSubmitMessage({
        type: 'error',
        text: 'Could not verify your humanity. Please try again.',
      });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({});
      setTimeout(() => {
        setFormSubmitMessage(null);
      }, 10000);
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <aside
      className={classNames(styles.newsletter, {
        [styles[`newsletter_${displayContext}`]]: displayContext,
      })}
    >
      <div
        className={classNames(
          styles.newsletter__container,
          { [styles.newsletter__container_split]: split },
          styles.newsletter__container_logo
        )}
      >
        <div className={styles.newsletter__logo} />
      </div>
      <div
        className={classNames(
          styles.newsletter__container,
          { [styles.newsletter__container_split]: split },
          styles.newsletter__container_form
        )}
      >
        <form
          className={styles.form}
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <SectionHeader priority={2}>Subscribe</SectionHeader>

          <p
            className={classNames(
              styles.newsletter__text,
              styles.newsletter__text_cta
            )}
          >
            Receive Dear Abby Free Every Day
          </p>

          <label
            className={labelClass(dirtyFields.email, errors.email)}
            htmlFor="email"
          >
            <span
              className={classNames(
                styles.newsletter__text,
                styles.newsletter__text_label
              )}
            >
              Email address:
            </span>
            <span
              className={iconClass(dirtyFields.email, errors.email)}
              data-testid="daSubscribe-icon"
            />
            <input
              className={styles.form__input}
              data-testid="daSubscribe-input"
              id="email"
              name="email"
              ref={register({
                required: true,
                validate: (value) => is.email(value),
              })}
              type="email"
            />
            {errors.email?.type === 'required' && (
              <p
                className={styles.form__error}
                data-testid="daSubscribe-alert"
                role="alert"
              >
                This field is required.
              </p>
            )}
            {errors.email?.type === 'validate' && (
              <p
                className={styles.form__error}
                data-testid="daSubscribe-alert"
                role="alert"
              >
                The input is not a valid email address. Ex:
                yourname@provider.com
              </p>
            )}
          </label>

          <div className={styles.form__group}>
            <Submit category="dark" outline>
              Subscribe Now
            </Submit>
            {formSubmitMessage && (
              <p
                className={styles[`form__${formSubmitMessage.type}`]}
                role="alert"
              >
                {formSubmitMessage.text}
              </p>
            )}
          </div>
        </form>
      </div>
    </aside>
  );
};

NewsletterForm.propTypes = {
  displayContext: PropTypes.oneOf(['sidebar', 'feature']),
  formId: PropTypes.string.isRequired,
  split: PropTypes.bool,
};

NewsletterForm.defaultProps = {
  split: false,
  displayContext: null,
};

export default NewsletterForm;
