import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import styles from './Headline.module.scss';

const Headline = ({ children, link, featureName, clickPosition, padded }) => (
  <h2
    className={classNames(styles.headline, {
      [styles.headline_padded]: padded,
    })}
  >
    <Link as={link.as} href={link.href}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <a
        className={styles.headline__link}
        onClick={() => {
          onPageNavigationEvent('feed', 'link', children);
          placementClickEvent('feed', clickPosition, children, featureName);
        }}
        onKeyUp={() => {
          onPageNavigationEvent('feed', 'link', children);
          placementClickEvent('feed', clickPosition, children, featureName);
        }}
      >
        {children}
      </a>
    </Link>
  </h2>
);

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  clickPosition: PropTypes.number.isRequired,
  featureName: PropTypes.string.isRequired,
  link: PropTypes.shape({
    as: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  padded: PropTypes.bool,
};

Headline.defaultProps = {
  padded: false,
};

export default Headline;
