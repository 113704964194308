import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './DividerWithContent.module.scss';

const DividerWithContent = ({ padded, children }) => (
  <div
    className={classNames(styles.divider, {
      [styles.divider_padded]: padded,
    })}
  >
    <div className={styles.divider__container}>
      <div className={styles.divider__wrapper}>{children}</div>
    </div>
  </div>
);

DividerWithContent.propTypes = {
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
};

DividerWithContent.defaultProps = {
  padded: false,
};

export default DividerWithContent;
